import request from '@utils/request';
import axios from 'axios';
var cancelGetBasket = function (message) { };
// Получение данных по остаткам
export var getBasket = function () {
    cancelGetBasket('Cancel');
    return request.post('/local/components/dvaslona/sale.basket.small/ajax.php', {
        cancelToken: new axios.CancelToken(function (c) {
            cancelGetBasket = c;
        }),
    });
};
// Получение данных по остаткам
export var removeItem = function (id) {
    var data = new FormData();
    data.append('remove[]', String(id));
    return request.post('/local/components/dvaslona/sale.basket.small/ajax.php', data);
};
// Переместить в корзину из избранного
export var moveFavToBasket = function (id) {
    var data = new FormData();
    data.append('action', 'moveToBasket');
    data.append('id', String(id));
    return request.post('/local/components/dvaslona/sale.basket.small/ajax.php', data);
};
// Обновление количества
var cancelSetQuantity = function (message) { };
export var setQuantity = function (id, quantity) {
    cancelSetQuantity('Cancel');
    var data = new FormData();
    data.append("quantity[" + id + "]", String(quantity));
    return request.post('/local/components/dvaslona/sale.basket.small/ajax.php', data, {
        cancelToken: new axios.CancelToken(function (c) {
            cancelGetBasket = c;
        }),
    });
};
// Обновление количества
var cancelSetCoupons = function (message) { };
export var updateCoupons = function (coupon, action) {
    cancelSetCoupons('Cancel');
    var data = new FormData();
    data.append('action', action);
    data.append("coupon", String(coupon));
    return request.post('/bitrix/components/dvaslona/sale.basket.small/ajax.php', data, {
        cancelToken: new axios.CancelToken(function (c) {
            cancelSetCoupons = c;
        }),
    });
};
export var refreshOrderAjax = function () {
    var _a;
    // @ts-ignore: Unreachable code error
    if (typeof BX === 'undefined') {
        return;
    }
    // @ts-ignore: Unreachable code error
    if ((_a = BX === null || BX === void 0 ? void 0 : BX.Sale) === null || _a === void 0 ? void 0 : _a.OrderAjaxComponent) {
        // @ts-ignore: Unreachable code error
        BX.Sale.OrderAjaxComponent.sendRequest('refreshOrderAjax');
        // @ts-ignore: Unreachable code error
        BX.Sale.OrderAjaxComponent.show(BX('bx-soa-region'));
    }
};
