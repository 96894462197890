import React, { useContext } from 'react';
import { store } from '@components/search.title/store-context';
/**
 * История поиска посетителя
 * @param param0
 * @returns
 */
var RalatedTopPhrases = React.memo(function () {
    var _a = useContext(store), ralatedTopPhrases = _a.state.ralatedTopPhrases, dispatch = _a.dispatch;
    if (!ralatedTopPhrases.length) {
        return null;
    }
    return (React.createElement("div", { className: "categories-search" },
        React.createElement("span", { className: "category-ttl" }, "\u0427\u0430\u0441\u0442\u043E \u0438\u0449\u0443\u0442"),
        ralatedTopPhrases.map(function (el) { return (React.createElement("a", { href: "/search/?q=" + el, key: el }, el)); })));
});
export default React.memo(RalatedTopPhrases);
