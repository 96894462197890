var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useContext, useState, useCallback, useEffect, } from 'react';
import { store } from '@components/search.title/store-context';
import debounce from 'lodash/debounce';
import request from '@utils/request';
import axios from 'axios';
/**
 * Основное поле ввода поискового запроса
 * @param param0
 * @returns
 */
var Search = React.memo(function () {
    var _a = useContext(store), dispatch = _a.dispatch, _b = _a.state, type = _b.type, query = _b.query;
    var _c = __read(useState('/search/'), 2), formAction = _c[0], setFormAction = _c[1];
    var cancelTokenSource = null;
    useEffect(function () {
        return function () {
            if (cancelTokenSource) {
                cancelTokenSource.cancel('stop erquest');
            }
        };
    }, []);
    var handleChange = useCallback(debounce(function (e) {
        if (type !== 'catalog') {
            return;
        }
        var bodyFormData = new FormData();
        bodyFormData.append('q', e.target.value);
        bodyFormData.append('ajax_call', 'y');
        bodyFormData.append('page', '1');
        if (cancelTokenSource) {
            cancelTokenSource.cancel('stop request');
        }
        cancelTokenSource = axios.CancelToken.source();
        dispatch({ type: 'setAjax', payload: true });
        request
            .post('/local/components/dvaslona/search.title/ajax.php', bodyFormData, { cancelToken: cancelTokenSource.token })
            .then(function (response) {
            dispatch({ type: 'setAjax', payload: false });
            dispatch({
                type: 'setData',
                payload: {
                    ralatedTopPhrases: response.data.ralatedTopPhrases,
                    sections: response.data.SECTIONS,
                    elements: response.data.elements,
                    historyUserPhrases: response.data.historyUserPhrases,
                },
            });
            try {
                // @ts-ignore: Unreachable code error
                dataLayer.push({
                    event: 'eventToGA',
                    eventCategory: 'SearchTitle',
                    eventAction: 'Предпросмотр результатов поиска',
                    eventLabel: e.target.value,
                });
            }
            catch (e) {
                console.error(e);
            }
        })
            .catch(function (e) {
            if (e.message !== 'stop request') {
                dispatch({ type: 'setAjax', payload: false });
            }
        })
            .finally(function () {
            // dispatch({ type: 'setAjax', payload: false });
        });
    }, 400), []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "search" },
            React.createElement("form", { action: "/search/" },
                React.createElement("input", { type: "text", name: "q", value: query, onFocus: function (e) {
                        dispatch({ type: 'setFocus', payload: true });
                    }, onBlur: function (e) {
                        setTimeout(function () {
                            //dispatch({ type: 'setFocus', payload: false });
                        }, 500);
                    }, onChange: function (e) {
                        dispatch({ type: 'setFocus', payload: true });
                        dispatch({ type: 'setQuery', payload: e.target.value });
                        handleChange(e);
                    }, size: 40, maxLength: 50, autoComplete: "off", className: "form-control search-input", placeholder: "\u041F\u043E\u0438\u0441\u043A", required: true }),
                React.createElement("button", { name: "s", type: "submit", className: "btn btn-link search-btn color-orange", "aria-label": "\u041F\u043E\u0438\u0441\u043A" },
                    React.createElement("i", { className: "fa fa-search" }))))));
});
export default Search;
