import React from 'react';
/**
 *
 * @param param0
 * @returns
 */
var Item = React.memo(function (_a) {
    var data = _a.data;
    return (React.createElement("div", { className: "item col-12 col-sm-6 col-lg-4", itemType: "http://schema.org/ImageObject", "data-snipped-production": data.ITEM_ID },
        React.createElement("div", { className: "product" },
            React.createElement("div", { className: "overlay-product" },
                React.createElement("div", { className: "image" },
                    React.createElement("a", { href: data.URL },
                        React.createElement("div", { className: "product-img-wrapper" },
                            React.createElement("img", { itemProp: "contentUrl", "data-main-img-id": "725988", src: data.img, alt: data.nameClean, title: data.nameClean, className: "img-responsive" })))),
                React.createElement("a", { className: "item-name", itemProp: "name", "data-role": "title", "data-id": "725988", href: data.URL }, data.nameClean),
                React.createElement("div", { className: "price" },
                    React.createElement("span", null, data.priceFormat)),
                React.createElement("div", { className: "action-control" },
                    React.createElement("a", { "data-id": data.ITEM_ID, href: data.URL, className: "btn btn-danger" },
                        React.createElement("span", { className: "" },
                            React.createElement("i", { className: "fa fa-shopping-basket" }, " "),
                            " \u041A\u0443\u043F\u0438\u0442\u044C",
                            ' ')))))));
});
export default React.memo(Item);
